/* App.css */

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}


.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.header {
  background-color: cornflowerblue;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.header h1 {
  font-size: 32px;
  margin: 20px 0;
  color: white;
}

.section {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.footer {
  background-color: cornflowerblue;
  color: white;
  padding: 10px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.card {
  padding: 10px;
  margin-bottom: 20px;
}

.error {
  color: red;
}

.input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 14px;
  box-sizing: border-box;
}

.token-details {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: left;
}

.token-image {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.token-img {
  width: 80%;
}

.token-animation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.table th,
.table td {
  border: 1px solid #ccc;
  padding: 8px;
  background: #f2f2f2;
}

.token-json {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  white-space: pre-wrap;
  overflow-x: auto;
}

.button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
}

.link {
  color: #007BFF;
  text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .container {
    width: 90%;
    margin: 10px auto;
  }
}

.link-container {
  margin-top: 20px;
  text-align: center;
}

.link {
  color: #007BFF;
  text-decoration: none;
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
}